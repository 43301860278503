<template>
    <div>
        <Header :title="'举报'" :leftClick="goBack"></Header>
        <div style="width:100%;padding: 20px;box-sizing: border-box;">
            <el-input v-model="info" placeholder="请输入" type="textarea" :rows="10"></el-input>
            <div class="btn-submit" @click="doSubmit">提交</div>
        </div>
    </div>
</template>
<script>
import Header from "../../components/app/Header.vue";
import {
    reporting
} from '../../api/app'
import {
    getAppUserId
} from '../../utils/store'
export default ({
    name: 'index',
    components: {
        Header,
    },
    data() {
        return {
            info: ''
        }
    },
    mounted() {

    },
    methods: {
        doSubmit() {
            if (this.info == '') {
                this.$message.error('请输入内容')
                return
            }
            reporting(getAppUserId(), this.info).then((res) => {
                this.$message.success('提交成功')
                this.goBack()
            })
        },
        goBack() {
            this.$router.go(-1)
        }
    }
})
</script>
<style lang="less" scoped>
.btn-submit {
    margin-top: 20px;
    color: white;
    font-size: 16px;
    border-radius: 34px;
    padding: 15px 0px;
    text-align: center;
    background-color: #5d5bb5;
}
</style>